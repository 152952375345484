import { Injectable } from '@angular/core';
import { Ga4Item } from '../models/analytics/ga4-item';
import { OrderJson } from '../models/order-json';
import { CourseResponseJson } from '../models/course-response-json';
import { OrderCourseJson } from '../models/order-course-json';
import { CartPositionResponseJson } from '../models/cart-position-response-json';
import { CartResponseJson } from '../models/cart-response-json';
import { SummaryResponseJson } from '../models/summary-response-json';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  purchase(order: OrderJson) {
    let orderSum = 0;
    const ga4Items = new Array<Ga4Item>();
    if (order) {
      for (const position of order.orderPositions) {
        const item: Ga4Item = {
          item_id: position.id.toString(),
          item_name: position.name,
          affiliation: undefined,
          coupon: position.voucherCode,
          discount: position.voucherDiscount,
          index: undefined,
          item_brand: position.organizerName,
          item_category: position.courseType,
          item_category2:
            position.courseScopes?.length >= 1
              ? position.courseScopes[0].name
              : undefined,
          item_category3:
            position.courseScopes?.length >= 2
              ? position.courseScopes[1].name
              : undefined,
          item_category4:
            position.courseScopes?.length >= 3
              ? position.courseScopes[2].name
              : undefined,
          item_category5:
            position.courseScopes?.length >= 4
              ? position.courseScopes[3].name
              : undefined,
          price: position.priceValue,
          quantity: 1
        };
        ga4Items.push(item);
        orderSum += position.priceValue;
      }
    }
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: order.orderNumber.toString(),
        value: orderSum,
        currency: 'EUR',
        coupon: order.voucherCode,
        items: ga4Items
      }
    });
  }

  addCustomerData(summaryData: SummaryResponseJson) {
    if (summaryData) {
      const dataLayer = window['dataLayer'] || [];
      dataLayer.push({ customer: null });
      dataLayer.push({
        customer: {
          email: summaryData.email,
          first_name: summaryData.name,
          last_name: summaryData.lastname,
          address: {
            street: summaryData.street,
            city: summaryData.city,
            postal_code: summaryData.zipCode,
            country: summaryData.country
          }
        }
      });
    }
  }

  viewItem(courseDetails: CourseResponseJson) {
    const ga4Items = new Array<Ga4Item>();
    const item: Ga4Item = {
      item_id: courseDetails.id.toString(),
      item_name: courseDetails.name,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined,
      index: undefined,
      item_brand: courseDetails.organizer.name,
      item_category: courseDetails.courseType,
      item_category2:
        courseDetails.professionalScopes?.length >= 1
          ? courseDetails.professionalScopes[0].name
          : undefined,
      item_category3:
        courseDetails.professionalScopes?.length >= 2
          ? courseDetails.professionalScopes[1].name
          : undefined,
      item_category4:
        courseDetails.professionalScopes?.length >= 3
          ? courseDetails.professionalScopes[2].name
          : undefined,
      item_category5:
        courseDetails.professionalScopes?.length >= 4
          ? courseDetails.professionalScopes[3].name
          : undefined,
      price: undefined,
      quantity: 1
    };
    ga4Items.push(item);
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: ga4Items
      }
    });
  }

  addToCart(selectedOrderCourse: OrderCourseJson) {
    const course = selectedOrderCourse.course;
    const packageData = selectedOrderCourse.packageData;
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    if (course) {
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: this.getPrice(course, selectedOrderCourse),
          items: [
            {
              item_id: selectedOrderCourse.courseId,
              item_name: course.name,
              affiliation: undefined,
              coupon: undefined,
              discount: undefined,
              index: undefined,
              item_brand: course.organizer.name,
              item_category: course.courseType,
              item_category2:
                course.professionalScopes?.length >= 1
                  ? course.professionalScopes[0].name
                  : undefined,
              item_category3:
                course.professionalScopes?.length >= 2
                  ? course.professionalScopes[1].name
                  : undefined,
              item_category4:
                course.professionalScopes?.length >= 3
                  ? course.professionalScopes[2].name
                  : undefined,
              item_category5:
                course.professionalScopes?.length >= 4
                  ? course.professionalScopes[3].name
                  : undefined,
              item_list_id: undefined,
              item_list_name: undefined,
              item_variant: undefined,
              location_id: undefined,
              price: this.getPrice(course, selectedOrderCourse),
              quantity: 1
            }
          ]
        }
      });
    }
    if (packageData) {
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: packageData.price.price,
          items: [
            {
              item_id: packageData.id,
              item_name: packageData.name,
              affiliation: undefined,
              coupon: undefined,
              discount: undefined,
              index: undefined,
              item_brand: packageData.organizer.name,
              item_category: packageData.type,
              item_list_id: undefined,
              item_list_name: undefined,
              item_variant: undefined,
              location_id: undefined,
              price: packageData.price.price,
              quantity: 1
            }
          ]
        }
      });
    }
  }

  private getPrice(
    course: CourseResponseJson,
    selectedOrderCourse: OrderCourseJson
  ) {
    const schedule = course.courseSchedules.find(
      (s) => s.id === selectedOrderCourse.courseScheduleId
    );
    return schedule.pricelevels.filter(
      (p) => p.id === selectedOrderCourse.priceLevelId
    )[0].priceValue;
  }

  removeFromCart(position: CartPositionResponseJson) {
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'EUR',
        value: position.discountPriceValue
          ? position.discountPriceValue
          : position.priceValue,
        items: [
          {
            item_id: position.courseId,
            item_name: position.name,
            affiliation: undefined,
            coupon: position.voucher?.voucherCode,
            discount: position.discountPriceValue
              ? position.priceValue - position.discountPriceValue
              : undefined,
            index: undefined,
            item_brand: position.organizer,
            item_category: position.courseType,
            item_category2:
              position.scopes?.length >= 1 ? position.scopes[0] : undefined,
            item_category3:
              position.scopes?.length >= 2 ? position.scopes[1] : undefined,
            item_category4:
              position.scopes?.length >= 3 ? position.scopes[2] : undefined,
            item_category5:
              position.scopes?.length >= 4 ? position.scopes[3] : undefined,
            item_list_id: undefined,
            item_list_name: undefined,
            item_variant: undefined,
            location_id: undefined,
            price: position.discountPriceValue
              ? position.discountPriceValue
              : position.priceValue,
            quantity: 1
          }
        ]
      }
    });
  }

  viewCart(cart: CartResponseJson) {
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: 'EUR',
        value: cart.sumValue,
        items: this.getCartItems(cart.positions)
      }
    });
  }

  startCheckOut(cart: CartResponseJson) {
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: cart.sumValue,
        coupon: cart.cartVoucher,
        items: this.getCartItems(cart.positions)
      }
    });
  }

  addShippingInfos(cart: CartResponseJson) {
    const dataLayer = window['dataLayer'] || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'EUR',
        value: cart.sumValue,
        coupon: cart.cartVoucher,
        items: this.getCartItems(cart.positions)
      }
    });
  }

  private getCartItems(positions: Array<CartPositionResponseJson>): Ga4Item[] {
    const items = new Array<Ga4Item>();
    positions.forEach((p) => {
      const item = {
        item_id: p.courseId ? p.courseId.toString() : p.packageId,
        item_name: p.name,
        affiliation: undefined,
        coupon: p.voucher?.voucherCode,
        discount: p.discountPriceValue
          ? p.priceValue - p.discountPriceValue
          : undefined,
        index: undefined,
        item_brand: p.organizer,
        item_category: p.courseType,
        item_category2: p.scopes?.length >= 1 ? p.scopes[0] : undefined,
        item_category3: p.scopes?.length >= 2 ? p.scopes[1] : undefined,
        item_category4: p.scopes?.length >= 3 ? p.scopes[2] : undefined,
        item_category5: p.scopes?.length >= 4 ? p.scopes[3] : undefined,
        item_list_id: undefined,
        item_list_name: undefined,
        item_variant: undefined,
        location_id: undefined,
        price: p.discountPriceValue ? p.discountPriceValue : p.priceValue,
        quantity: 1
      } as Ga4Item;
      items.push(item);
    });
    return items;
  }
}
