import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';
import { RequestEventService } from '../services/request-event.service';

@Directive({
  standalone: true,
  selector: '[appRequestSpinner]'
})
export class RequestSpinnerDirective implements AfterViewInit {
  // RequestOrRedirectSpinnerDirective
  @Input()
  appRequestSpinner;

  private spinnerContent = `<fa-icon  class="button-icon ng-fa-icon" ><svg aria-hidden="true" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg></fa-icon>`;
  private defaultContent;
  private width;
  private height;

  constructor(
    private requestEventService: RequestEventService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    requestEventService.events.subscribe((event) => {
      if (event === this.appRequestSpinner) {
        this.toggleSpinner();
      } else if (event === 'error') {
        this.toggleOff();
      }
    });
  }

  ngAfterViewInit() {
    this.width = this.el.nativeElement.offsetWidth;
    this.height = this.el.nativeElement.offsetHeight;
  }

  @HostListener('click')
  onclick() {
    this.requestEventService.throw(this.appRequestSpinner);
  }

  private toggleSpinner() {
    if (this.el.nativeElement.innerHTML === this.spinnerContent) {
      this.el.nativeElement.innerHTML = this.defaultContent;
    } else {
      this.defaultContent = this.el.nativeElement.innerHTML;
      if (this.width !== 0) {
        this.el.nativeElement.style.width = this.width + 'px';
        this.el.nativeElement.style.height = this.height + 'px';
      }
      this.el.nativeElement.innerHTML = this.spinnerContent;
    }
  }

  private toggleOff() {
    if (this.el.nativeElement.innerHTML === this.spinnerContent) {
      this.el.nativeElement.innerHTML = this.defaultContent;
    }
  }
}
