import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {Observable, of} from 'rxjs';
import {CartJson} from './models/cart-json';
import {CartPositionResponseJson} from './models/cart-position-response-json';
import {CartResponseJson} from './models/cart-response-json';
import {OrderCourseJson} from './models/order-course-json';
import {PositionSummaryJson} from './models/position-summary-json';
import {Api} from './services/api.service';
import {AnalyticsService} from './services/analytics.service';
import {CourseResponseJson} from './models/course-response-json';
import {CustomerPackage} from './models/customer-package';
import {PackageService} from './services/package.service';
import {ProfessionalScopesJson} from './models/professional-scope-json';
import {CoursePackage} from '../../build/course-api';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartAmount = 0;
  private cart: CartResponseJson;

  constructor(
    private api: Api,
    private keycloakService: KeycloakService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private packageService: PackageService
  ) {
  }

  public getCartAmount(): number {
    return this.cartAmount;
  }

  public update() {
    if (this.keycloakService.isLoggedIn() && window.location.pathname.indexOf('/check') < 0) {
      this.api.getCartAmount().subscribe((response) => {
        this.cartAmount = Number(response.headers.get('Content-Length'));
      });
    } else {
      const cart: CartJson = JSON.parse(localStorage.getItem('cart'));
      if (cart) {
        this.cartAmount = cart.positions.length;
        this.loadCartFromStorage().subscribe((cart) => this.setCart(cart));
      }
    }
  }

  addToCart(
    selectedOrderCourse: OrderCourseJson,
    rejectedSchedules: number[]
  ): Observable<number[]> {
    const observable = of(rejectedSchedules);
    if (this.keycloakService.isLoggedIn()) {
      this.keycloakService.getToken().then((token) => {
        this.api.addToCart(selectedOrderCourse, token).subscribe(
          () => {
            this.update();
            this.analyticsService.addToCart(selectedOrderCourse);
            this.router.navigate(['warenkorb']);
          },
          () => {
            rejectedSchedules.push(selectedOrderCourse.courseScheduleId);
          }
        );
      });
    } else {
      let cart: CartJson = JSON.parse(localStorage.getItem('cart'));
      if (cart) {
        let found = false;
        for (const position of cart.positions) {
          if (
            position.courseId === selectedOrderCourse.courseId &&
            position.courseScheduleId === selectedOrderCourse.courseScheduleId
          ) {
            if (
              selectedOrderCourse.packageIncluded &&
              !position.packageIncluded
            ) {
              position.packageIncluded = true;
              this.packageService.handleAddedCourse(
                selectedOrderCourse.course.professionalScopes,
                selectedOrderCourse.course.organizer.id,
                selectedOrderCourse.course.durationValue
              );
            }
            if (
              !selectedOrderCourse.packageIncluded &&
              position.packageIncluded
            ) {
              this.packageService.handleRemovedCourse(
                position.packageIncluded,
                position.course.durationValue,
                position.courseId,
                position.packageId,
                position.course.type,
                position.course.organizer.id,
                position.course.professionalScopes.map((s) => s.id)
              );
              position.packageIncluded = false;
            }
            position.priceLevelId = selectedOrderCourse.priceLevelId;
            position.packageIncluded = selectedOrderCourse.packageIncluded;
            found = true;
          }
        }
        if (!found) {
          cart.positions.push(selectedOrderCourse);
        }
      } else {
        cart = new (class implements CartJson {
          cartId: number;
          hash: string;
          positions: Array<OrderCourseJson>;
          cartVoucher: any;
          voucherCodes: Array<string>;
          rejectedVoucher: string;
          unusedVoucher: string;
        })();
        cart.positions = new Array<OrderCourseJson>();
        cart.positions.push(selectedOrderCourse);
      }
      localStorage.setItem('cart', JSON.stringify(cart));
      this.update();
      this.analyticsService.addToCart(selectedOrderCourse);
      this.router.navigate(['warenkorb']);
    }
    return observable;
  }

  clearCart() {
    this.cartAmount = 0;
  }

  deleteSummaryPosition(position: PositionSummaryJson) {
    const sessionCart: CartJson = JSON.parse(localStorage.getItem('cart'));
    const indexPos: number = sessionCart.positions.findIndex(
      (value) => value.priceLevelId === position.coursePricelevelResponse.id
    );
    if (indexPos !== -1) {
      sessionCart.positions.splice(indexPos, 1);
    }
    localStorage.setItem('cart', JSON.stringify(sessionCart));
  }

  updateScope(position: CartPositionResponseJson, scopeId: number, categoryId?: number) {
    if(position.id) {
      this.api.updateCartPositionScope(position.id, scopeId, categoryId).subscribe({})
    } else {
      const sessionCart: CartJson = JSON.parse(localStorage.getItem('cart'));
      const indexPos: number = sessionCart.positions.findIndex(
        (value) =>
          value.courseId === position.courseId &&
          value.priceLevelId === position.pricelevelId &&
          value.packageId === position.packageId &&
          (!value.courseScheduleId || value.courseScheduleId === position.scheduleId)
      );
      if (indexPos !== -1) {
        let orderCourseJson = sessionCart.positions[indexPos];
        orderCourseJson.scopeId = scopeId;
        orderCourseJson.categoryId = categoryId;
      }
      localStorage.setItem('cart', JSON.stringify(sessionCart));
    }
  }

  deletePosition(position: CartPositionResponseJson) {
    if (position.id) {
      this.api.deleteCartPosition(position.id).subscribe({
        next: (result) => {
          this.cart = result;
          this.update();
          this.analyticsService.removeFromCart(position);
        }
      });
    } else {
      // const index: number = this.cart.positions.indexOf(position);
      // if (index !== -1) {
      //   this.cart.positions.splice(index, 1);
      // }
      const sessionCart: CartJson = JSON.parse(localStorage.getItem('cart'));
      const pos: OrderCourseJson = new (class implements OrderCourseJson {
        courseId: number;
        packageId: number;
        courseScheduleId: number;
        priceLevelId: number;
        scopeId: number;
        categoryId: number;
        voucher: any;
        course: CourseResponseJson;
        packageOrder: boolean;
        packageIncluded: boolean;
        packageData: CoursePackage;
      })();
      pos.courseId = position.courseId;
      pos.packageId = position.packageId;
      if (position.scheduleId) {
        pos.courseScheduleId = position.scheduleId;
      }
      pos.priceLevelId = position.pricelevelId;
      const indexPos: number = sessionCart.positions.findIndex(
        (value) =>
          value.courseId === pos.courseId &&
          value.priceLevelId === pos.priceLevelId &&
          value.packageId === pos.packageId &&
          (!value.courseScheduleId ||
            value.courseScheduleId === pos.courseScheduleId)
      );
      if (indexPos !== -1) {
        sessionCart.positions.splice(indexPos, 1);
      }
      localStorage.setItem('cart', JSON.stringify(sessionCart));
      this.updateCart();
      this.analyticsService.removeFromCart(position);
      this.handleRemovedCourse(position);
    }
  }

  setCart(cart: CartResponseJson) {
    this.cart = cart;
    this.cartAmount = cart.positions?.length;
  }

  getCart(): CartResponseJson {
    return this.cart;
  }

  hasVerificationStep() {
    return (
      this.cart.positions.filter((p) => p.verificationNeeded === true).length >
      0
    );
  }

  convertToStorage(cart: CartResponseJson) {
    if (!this.keycloakService.isLoggedIn()) {
      let cartStorage: CartJson = JSON.parse(localStorage.getItem('cart'));
      if (!cartStorage) {
        cartStorage = new (class implements CartJson {
          cartId: number;
          hash: string;
          positions: Array<OrderCourseJson>;
          cartVoucher: any;
          voucherCodes: Array<string>;
          rejectedVoucher: string;
          unusedVoucher: string;
        })();
        cartStorage.positions = new Array<OrderCourseJson>();
      }
      if (cart.cartVoucher) {
        cartStorage.cartVoucher = cart.cartVoucher;
      } else {
        cartStorage.cartVoucher = undefined;
      }
      cartStorage.positions = cart.positions.map((p) => {
        return {
          courseId: p.courseId,
          packageId: p.packageId,
          priceLevelId: p.pricelevelId,
          courseScheduleId: p.scheduleId,
          scopeId: p.scopeId,
          categoryId: p.categoryId,
          packageIncluded: p.packageIncluded,
          course: {
            id: p.courseId,
            durationValue: p.durationValue,
            type: p.type,
            organizer: {
              id: p.organizerId
            },
            professionalScopes: this.createScopes(p.scopeIds)
          } as CourseResponseJson,
          voucher: {
            voucherValue: p.voucher?.voucherValue,
            voucherValueType: p.voucher?.voucherValueType,
            voucherCode: p.voucher?.voucherCode
          }
        } as OrderCourseJson;
      });
      cartStorage.rejectedVoucher = cart.rejectedVoucher;
      cartStorage.unusedVoucher = cart.unusedVoucher;
      localStorage.setItem('cart', JSON.stringify(cartStorage));
      const cp = localStorage.getItem('cp');
      if (cp) {
        const packages = JSON.parse(cp) as CustomerPackage[];
        cart.positions
          .filter((p) => p.packageId != null)
          .forEach((p) => {
            if (p.remainingCredits != undefined) {
              packages
                .filter(
                  (customerPackage) =>
                    customerPackage.packageId === p.packageId
                )
                .forEach(
                  (customerPackage) =>
                    (customerPackage.remainingCredits = p.remainingCredits)
                );
            }
          });
        localStorage.setItem('cp', JSON.stringify(packages));
      }
    }
  }

  private createScopes(scopeIds: number[]): ProfessionalScopesJson[] {
    return scopeIds?.map((scopeId) => {
      return {
        id: scopeId
      } as ProfessionalScopesJson;
    });
  }

  redeemVoucher(voucher: string) {
    if (this.keycloakService.isLoggedIn()) {
      this.keycloakService.getToken().then(() => {
        this.api.addVoucherToCart(voucher).subscribe((cart) => {
          this.cart = cart;
          this.update();
        });
      });
    } else {
      this.updateCart(voucher);
    }
  }

  private updateCart(voucher?: string) {
    let cart: CartJson = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      if (!cart.voucherCodes) {
        cart.voucherCodes = new Array<string>();
      }
      if (!cart.voucherCodes.includes(voucher)) {
        cart.voucherCodes.push(voucher);
      }
      if (cart.positions) {
        const positionVouchers = cart.positions
          .filter((pos) => pos.voucher?.voucherCode)
          .map((p) => p.voucher.voucherCode);
        if (positionVouchers.length > 0) {
          cart.voucherCodes.push(...new Set(positionVouchers));
        }
      }
      if (
        cart.cartVoucher &&
        !cart.voucherCodes.includes(cart.cartVoucher.voucherCode)
      ) {
        cart.voucherCodes.push(cart.cartVoucher.voucherCode);
      }
      cart.rejectedVoucher = undefined;
      this.api.getCartByPositions(cart).subscribe((cart) => {
        this.setCart(cart);
        this.convertToStorage(cart);
      });
    }
  }

  removeFromPackage(position: CartPositionResponseJson): Promise<boolean> {
    if (this.keycloakService.isLoggedIn()) {
      return this.updateAfter(this.api.removeFromPackage(position.id));
    } else {
      let cart: CartJson = JSON.parse(localStorage.getItem('cart'));
      cart.positions
        .filter((p) => p.courseId === position.courseId)
        .map((p) => (p.packageIncluded = false));
      localStorage.setItem('cart', JSON.stringify(cart));
      this.updateCart();
      return new Promise((resolve) => {
        resolve(true)
      });
    }
  }

  removeVoucher(voucher: string): Promise<boolean> {
    if (this.keycloakService.isLoggedIn()) {
      return this.updateAfter(this.api.removeVoucherFromCart(voucher));
    } else {
      let cart: CartJson = JSON.parse(localStorage.getItem('cart'));
      if (cart) {
        if (cart.cartVoucher?.voucherCode === voucher) {
          cart.cartVoucher = undefined;
        }
        cart.positions
          .filter((p) => p.voucher.voucherCode === voucher)
          .forEach((p) => (p.voucher.voucherCode = undefined));
        cart.rejectedVoucher = undefined;
        return this.updateAfter(this.api.getCartByPositions(cart));
      }
    }
  }

  private updateAfter(observable: Observable<any>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: (cart) => {
          this.setCart(cart);
          this.convertToStorage(cart);
          resolve(true);
        },
        error: () => {
          reject(false);
        }
      });
    });
  }

  public handleRemovedCourse(course: CartPositionResponseJson) {
    if (!this.keycloakService.isLoggedIn()) {
      this.packageService.handleRemovedCourse(
        course.packageIncluded,
        course.durationValue,
        course.courseId,
        course.packageId,
        course.type,
        course.organizerId,
        course.scopeIds
      );
    }
  }

  loadCartFromStorage() {
    const cart: CartJson = JSON.parse(localStorage.getItem('cart'));
    const positionVouchers = new Set(
      cart.positions.map((p) => p.voucher?.voucherCode)
    );
    if (!cart.voucherCodes) {
      cart.voucherCodes = [];
    }
    if (positionVouchers.size > 0) {
      cart.voucherCodes.push(...positionVouchers);
    }
    if (cart.cartVoucher) {
      cart.voucherCodes.push(cart.cartVoucher.voucherCode);
    }
    cart.rejectedVoucher = undefined;
    return this.api.getCartByPositions(cart);
  }


}
