import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SiteConfiguration } from '../configuration/site-configuration';
import { MessageJson } from '../models/message-json';

@Injectable({
  providedIn: 'root'
})
export class FavIconService {
  constructor(private titleService: Title) {}

  initFavoriteIconsAndTitle(config: SiteConfiguration) {
    const style: HTMLLinkElement = document.querySelector('#jurdemy-style');
    style.href = `${config.theme}.css`;
    style.onload = function () {
      document.querySelector('body').classList.remove('loading');
    };

    const appleIcon: HTMLLinkElement =
      document.querySelector('#apple-touch-icon');
    appleIcon.href = `/assets/${config.theme}/favicons/apple-touch-icon.png`;
    const appleIcon60: HTMLLinkElement = document.querySelector(
      '#apple-touch-icon60'
    );
    appleIcon60.href = `/assets/${config.theme}/favicons/apple-touch-icon-60x60.png`;
    const appleIcon76: HTMLLinkElement = document.querySelector(
      '#apple-touch-icon76'
    );
    appleIcon76.href = `/assets/${config.theme}/favicons/apple-touch-icon-76x76.png`;
    const appleIcon120: HTMLLinkElement = document.querySelector(
      '#apple-touch-icon120'
    );
    appleIcon120.href = `/assets/${config.theme}/favicons/apple-touch-icon-120x120.png`;
    const appleIcon152: HTMLLinkElement = document.querySelector(
      '#apple-touch-icon152'
    );
    appleIcon152.href = `/assets/${config.theme}/favicons/apple-touch-icon-152x152.png`;
    const appleIcon180: HTMLLinkElement = document.querySelector(
      '#apple-touch-icon180'
    );
    appleIcon180.href = `/assets/${config.theme}/favicons/apple-touch-icon-180x180.png`;
    const icon32: HTMLLinkElement = document.querySelector('#icon32');
    icon32.href = `/assets/${config.theme}/favicons/favicon-32x32.png`;
    const icon16: HTMLLinkElement = document.querySelector('#icon16');
    icon16.href = `/assets/${config.theme}/favicons/favicon-16x16.png`;
    const icon96: HTMLLinkElement = document.querySelector('#icon96');
    icon96.href = `/assets/${config.theme}/favicons/favicon-96x96.png`;
    const icon196: HTMLLinkElement = document.querySelector('#icon196');
    icon196.href = `/assets/${config.theme}/favicons/favicon-196x196.png`;
    const maskIcon: HTMLLinkElement = document.querySelector('#mask-icon');
    maskIcon.href = `/assets/${config.theme}/favicons/safari-pinned-tab.svg`;
    const favIcon: HTMLLinkElement = document.querySelector('#fav-icon');
    favIcon.href = `/assets/${config.theme}/favicons/favicon.ico`;
    const manifest: HTMLLinkElement = document.querySelector('#manifest');
    manifest.href = `/assets/${config.theme}/favicons/site.webmanifest`;
    const browserconfig: HTMLMetaElement =
      document.querySelector('#browserconfig');
    browserconfig.content = `/assets/${config.theme}/favicons/browserconfig.xml`;
    const image: HTMLMetaElement = document.querySelector('#image');
    image.content = `/assets/${config.theme}/logo.jpg`;

    this.titleService.setTitle(config.name);
  }

  initMetaData(messages: MessageJson) {
    const title: HTMLMetaElement = document.querySelector('#title');
    title.content = messages.defaultSiteTitle;
    const description: HTMLMetaElement = document.querySelector('#description');
    description.content = messages.defaultMetaDescription;
  }
}
