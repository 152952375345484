<nav class="navbar navbar-expand-lg navbar-dark bg-footer px-3">
  <div class="d-flex footer-container flex-xs-row-reverse flex-md-row">
    <div class="col-12 col-md-6 pt-2 pt-md-0 p-0">
      <div class="row mx-1">
        <div class="col-6 col-md-5 col-lg-4">
          <div class="row">
            <div
              *ngFor="let footer of getFooterLinks(); let i = index"
              class="col-12 p-0"
            >
              <a
                *ngIf="i < getFooterLinks().length / 2"
                class="nav-item nav-link footer-link"
                [routerLink]="[footer.url]"
              >
                {{ footer.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-5 col-lg-3">
          <div class="row">
            <div
              *ngFor="let footer of getFooterLinks(); let i = index"
              class="col-12 p-0"
            >
              <a
                *ngIf="i >= getFooterLinks().length / 2"
                class="nav-item nav-link footer-link"
                [routerLink]="[footer.url]"
              >
                {{ footer.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 col-lg-5 d-flex justify-content-center">
            <a
              *ngFor="let socialLink of getSocialMediaLinks()"
              [attr.aria-label]="socialLink.name"
              class="py-0 px-3 px-sm-2"
              href="{{ socialLink.url }}"
              target="_blank"
              rel="noopener"
            >
              <fa-icon
                *ngIf="socialLink.name === 'FACEBOOK'"
                [icon]="faFacebookSquare"
                size="lg"
              ></fa-icon>
              <fa-icon
                *ngIf="socialLink.name === 'XING'"
                [icon]="faXingSquare"
                size="lg"
              ></fa-icon>
              <fa-icon
                *ngIf="socialLink.name === 'TWITTER'"
                [icon]="faXTwitter"
                size="lg"
              ></fa-icon>
              <fa-icon
                *ngIf="socialLink.name === 'INSTAGRAM'"
                [icon]="faInstagram"
                size="lg"
              ></fa-icon>
            </a>
        </div>
      </div>
    </div>
    <div
      *ngIf="config.isFeatureActive('NEWSLETTER')"
      class="col-12 col-md-6 p-0 m-0"
    >
      <app-newsletter></app-newsletter>
    </div>
  </div>
</nav>
